import React from 'react';
import logo from './logo.svg';
import './App.css';
import ContactMeScreen from './screens/ContactMe/ContactMeScreen';
import HomeScreen from './screens/Home/HomeScreen';
import AboutMeScreen from './screens/AboutMe/AboutMeScreen';
import ProjectScreen from './screens/Projects/ProjectScreen';
import WorkScreen from './screens/Work/WorkScreen';
import ShowPiece from './screens/Clouds/ShowPiece';
import Services from './screens/Services/Services';

import Footer from './components/Footer';
import ContactMenu from './components/ContactMenu';
import PageMenu from './components/PageMenu';

function App() {
    return (
    <div className="App">
      <div className="page-container">
        <PageMenu />
        <ContactMenu />
        <ShowPiece />
        <div className="lower-screen">
          <AboutMeScreen />
          <WorkScreen />
          <ProjectScreen />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
