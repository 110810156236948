import React from 'react';
import './HomeScreen.css';
import { GoHome } from 'react-icons/go';

class HomeScreen extends React.Component {
    constructor()
    {
        super();
        this.PageTitle = "Home";
        this.menuPosition = 0;
        this.menuIcon = <GoHome />;
    }    

    render() {
        return  (
            <div className="App" id="0">
                <div className="App-Header">
                    <h2 className="small-header greeting-text">
                        Hello!
                    </h2>
                    <h1 className="name-text">
                        I'm Tom Ward.
                    </h1>
                    <h2 className="slogan-text">
                        Software Engineer
                    </h2>
                    <h5 className="transparent-text short-about-me-text">
                        Based in Jacksonville, Fl
                    </h5>
                </div>
            </div>
            );
        }
  }
  export default HomeScreen;