import React from 'react';
import './ProjectTile.css';
import {Panel, PanelGroup, Tag, TagGroup} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import {Animated} from "react-animated-css";

const ProjectTile = props => {
    const domRef = React.useRef();
    const [isVisible, setVisible] = React.useState(true);

    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      return () => observer.unobserve(domRef.current);
    }, []);

    return  (
        <div className="project-tile-wrapper" ref={domRef}>
            <Animated animationIn={props.imageSide ? "slideInRight" : "slideInLeft" } animationOut="fadeOut" isVisible={isVisible}>
            <Panel header={props.projectTitle} shaded>
                {props.imageSide && 
                    <div className="panel-container">
                            <Panel shaded bordered bodyFill style={{ display: 'inline-block' }}>
                            <img src={props.projectImage} height="360" />
                        </Panel>
                        <Panel className="project-description-panel">
                            <p className="project-description">
                                {props.projectDescription}
                            </p>
                        </Panel>            
                    </div>
                }
                {!props.imageSide && 
                    <div className="panel-container">
                    <Panel className="project-description-panel">
                            <p className="project-description">
                                {props.projectDescription}
                            </p>
                        </Panel>            
                        <Panel shaded bordered bodyFill style={{ display: 'inline-block'}}>
                            <img src={props.projectImage} height="360" />
                        </Panel>
                    </div>
                    }
                    <div className="project-tag-container">
                        <TagGroup>
                            {
                                props.projectTags.map(
                                    tag => {
                                        return (
                                            <Tag
                                                color="violet"
                                            >
                                                {tag} 
                                            </Tag>
                                        )
                                    }
                                )
                            }
                        </TagGroup>
                    </div>
                </Panel>       
                </Animated> 
                </div>
            )
}

  export default ProjectTile;