import React, { useEffect, useState } from 'react';
import './ShowPiece.css';
import {Panel, PanelGroup, Tag, TagGroup} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import Cloud from '../../components/Cloud'

const ShowPiece = props => {
    const cloudColors = [
        "rgba(246, 114, 128, .5)",
        "rgba(246, 114, 128, .8)",
        "rgba(192, 108, 132, .5)",
        "rgba(192, 108, 132, .8)",
        "rgba(108, 91, 123, .5)",
        "rgba(108, 91, 123, .8)",
    ];
    const [clouds, setClouds] = useState([{
        bumps: 5,
        color: cloudColors[Math.floor(Math.random() * cloudColors.length)],
        direction:  Math.random() > .5 ? "slideleft" : "slideright"
    },
    {
        bumps: 8,
        color: cloudColors[Math.floor(Math.random() * cloudColors.length)],
        direction:  Math.random() > .5 ? "slideleft" : "slideright"
    },
    {
        bumps: 6,
        color: cloudColors[Math.floor(Math.random() * cloudColors.length)],
        direction:  Math.random() > .5 ? "slideleft" : "slideright"
    }
]);

    const generateClouds = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                var cloudsTemp = [...clouds];
                if(cloudsTemp.length > 9) {
                    cloudsTemp.splice(0,cloudsTemp.length - 5);
                }
                var numClouds = Math.round(Math.random() * 1) + 1;
                for(var i = 0; i < numClouds; i++) {
                    cloudsTemp.push({
                        bumps: Math.floor(Math.random() * 10) + 3,
                        color: cloudColors[Math.floor(Math.random() * cloudColors.length)],
                        direction: Math.random() > .5 ? "slideleft" : "slideright"
                    })    
                }
                setClouds(cloudsTemp);
                resolve();
            }, 28000);
        }).then(() => {
        })
    }

    useEffect(() => {
        generateClouds();
    }, [generateClouds])

    return  (
        <div className="landscape-wrapper" onClick={() => window.open(this.props.projectUrl)} id="0">
            <div className="greeting-wrapper">
                <div className="greeting-container">
                    <div className="greeting-intro">
                        Hello!
                    </div>
                    <div className="greeting-name">
                        I'm Tom Ward.
                    </div>
                </div>
            </div>
            <div className="mountain">

            </div>
            <div className="sun">

            </div>
            {
                clouds.map((item, i) => 
                    <Cloud 
                        id={i}
                        key={i}
                        numBumps={item.bumps}
                        color={item.color}
                        direction={item.direction}
                    />
                    )
            }
        </div>
    )
}
export default ShowPiece;
