import React from 'react';
import './ContactMenu.css';

import ContactMenuItem from './ContactItem';
import {FaLinkedin, FaGithub} from 'react-icons/fa';
import {AiTwotoneMail} from 'react-icons/ai';

class ContactMenu extends React.Component {    
    render() {
        var contactDetails = [
                {
                    PageTitle: "GitHub",
                    MenuPosition: 0,
                    MenuIcon: <FaGithub />,
                    MenuLink: "https://www.github.com/TomWrd"
                },
                {
                    PageTitle: "Email",
                    MenuPosition: 0,
                    MenuIcon: <AiTwotoneMail />,
                    MenuLink: "mailto:tomward9922@gmail.com"
                },
                {
                    PageTitle: "LinkedIn",
                    MenuPosition: 0,
                    MenuIcon: <FaLinkedin />,
                    MenuLink: "https://www.linkedin.com/in/thomas-ward92/"
                },

        ];
        return  (
                <div className={"contact-menu-wrapper"} >
                    {
                    contactDetails.map(
                            (page, i) => <ContactMenuItem   
                                                    key={'contact-menu-item-'+i}
                                                    menuTitle={page.PageTitle} 
                                                    menuPosition={page.MenuPosition}                                                     
                                                    menuIcon={page.MenuIcon}
                                                    menuLink={page.MenuLink}
                                                    />
                        )
                    }
                </div>
            );

        }
  }
  export default ContactMenu;