import React, { useEffect, useState } from 'react';
import './ContactMeScreen.css';
import { FaBeer, FaTelegramPlane } from 'react-icons/fa';
import { TextField, Button } from '@material-ui/core';
import axios from 'axios';
import { BounceLoader } from 'react-spinners';


class ContactMeScreen extends React.Component {
    constructor()
    {
        super();
        this.state = {
            isLoading: false,
            messageSent: false,
        };

        this.PageTitle = "Let's Talk";
        this.menuPosition = 4;
        this.menuIcon = <FaBeer />;

    
        this.sendMessage = async () => {

            this.setState({
                ...this.state,
                isLoading: true
            })
            console.log("POSTING: " + JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            }));
            await axios.post('/api/send-message', {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            })
            .then(response => {
                if(response.status === 200) {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        messageSent: true
                    })
                }    
            });
        }    
    
    }    


    
    render() {
        return  <div className="screen-wrapper" id="4">
        <div className="contact-me-header">
            <h3>
                Let's Talk
            </h3>
        </div>
        <div className="form-container">
            {
                this.state.isLoading && 
                <div className="form-row loading">
                    <BounceLoader color="white" />
                </div>
            }
            {
                this.state.messageSent && !this.state.isLoading &&
                <div className="form-row">
                    <div className="thank-you-message">
                        Thank you! I'll be in touch soon! <br /> -Tom Ward
                    </div>
                </div>
            }
            {
                !this.state.messageSent && !this.state.isLoading &&
                    <form className="form-wrapper">
                        <div className="form-row single">
                            <TextField id="name" label="Name" variant="outlined" onChange={e => this.setState({...this.state, name: (e.target.value)})}  required />
                        </div>
                        <div className="form-row split">
                            <TextField id="email" label="Email" variant="outlined"  onChange={e => this.setState({...this.state, email: (e.target.value)})}  required />
                            <TextField id="phone" label="Phone" variant="outlined" onChange={e => this.setState({...this.state, phone: (e.target.value)})}  />
                        </div>
                        <div className="form-row single">
                            <TextField placeholder="What can I do for you?" id="description" rows={4} multiline label="" variant="outlined" onChange={e => this.setState({...this.state, message: (e.target.value)})} />
                        </div>
                        <div className="form-row submit">
                            {
                                this.state.name && this.state.email && 
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    startIcon={<FaTelegramPlane />}
                                    onClick={this.sendMessage}
                                    >
                                    Send
                                </Button>
                            }
                            {
                                (!this.state.name || !this.state.email) && 
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    disabled
                                    startIcon={<FaTelegramPlane />}
                                    onClick={this.sendMessage}
                                    >
                                    Send
                                </Button>
                            }
                        </div>
                    </form>
            }
        </div>
    </div>
    }
}



export default ContactMeScreen;