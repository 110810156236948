import React from 'react';
class Project {
    constructor(projectTitle, projectImage, projectDescription, projectTags, projectGit, projectUrl)
    {
        this.projectTitle = projectTitle;
        this.projectImage = projectImage;
        this.projectDescription = projectDescription;
        this.projectTags = projectTags;
        this.projectGit = projectGit;
    }    
}

export default Project;