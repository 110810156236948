import React from 'react';
import './AboutMeScreen.css';
import {Animated} from "react-animated-css";
import awsImage from '../../aws.png';
import databaseImage from '../../database.png';
import jsImage from '../../javascript.png';
import linuxImage from '../../linux.png';
import devopsImage from '../../devops.png';
import azureImage from '../../azure.png';
const AboutMeScreen = props => {
    const domRef = React.useRef();
    const [isVisible, setVisible] = React.useState(true);

    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      return () => observer.unobserve(domRef.current);
    }, []);
    
    const familyPicture = require('../../profile_picture.png');
    return  <div className="about-me-wrapper" id="1" ref={domRef}>
                <div className="about-me-header">
                    <h3>
                    About Me
                    </h3>
                </div>
                <div className="about-me-container">
                    <div className="about-me-text">
                        I'm a software engineer in Florida passionate about building reliable, high quality software.
                        <br></br>
                        <br></br>
                        I have a B.S. in Computer Science from the University of North Florida with specializations in AI & web development.  
                    </div>
                    <div className="about-me-image">
                        <img src={familyPicture}></img>
                    </div>
                </div>
                <div className="skills-container">
                        <h3>
                            Skills
                        </h3>
                        <ul className="skills-list">
                        <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={isVisible} animationInDelay={0}>
                            <li>
                                <div className="item-icon">
                                    <img src={jsImage} className="icon-image" />
                                </div>
                                <div className="item-details">
                                    <div className="item-header"> 
                                    JavaScript / TypeScript
                                    </div>
                                    React, React Native, Node, GraphQL                            
                                </div>
                            </li>
                            </Animated>

                            <Animated animationIn="bounceInRight" animationOut="fadeOut" isVisible={isVisible}  animationInDelay={0}>
                            <li>
                                <div className="item-icon">
                                    <img src={devopsImage} className="icon-image" />
                                </div>
                                <div className="item-details">
                                <div className="item-header">
                                    Infrastructure As Code
                                </div>
                                    Docker, Serverless
                                </div>
                            </li>
                            </Animated>

                            <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={isVisible}  animationInDelay={250}>
                            <li>
                                <div className="item-icon">
                                    <img src={azureImage} className="icon-image" />
                                </div>
                                <div className="item-details">
                                <div className="item-header">
                                    Azure
                                </div>
                                Web apps, bot framework, media services, dev ops
                                </div>
                            </li>
                            </Animated>

                            <Animated animationIn="bounceInRight" animationOut="fadeOut" isVisible={isVisible}  animationInDelay={250}>
                            <li>
                                <div className="item-icon">
                                    <img src={awsImage} className="icon-image" />
                                </div>
                                <div className="item-details">
                                <div className="item-header">
                                    AWS
                                </div>
                                Lambda, DynamoDB, EC2, Amplify, Elasticsearch
                                </div>
                            </li>
                            </Animated>

                            <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={isVisible}  animationInDelay={500}>
                            <li>
                                <div className="item-icon">
                                    <img src={devopsImage} className="icon-image" />
                                </div>
                                <div className="item-details">
                                <div className="item-header">
                                    Automated Testing
                                </div>
                                Appium, Cypress, Jest
                                </div>
                            </li>
                            </Animated>

                            <Animated animationIn="bounceInRight" animationOut="fadeOut" isVisible={isVisible} animationInDelay={500}>
                            <li>
                                <div className="item-icon">
                                    <img src={databaseImage} className="icon-image" />
                                </div>
                                <div className="item-details">
                                <div className="item-header">
                                    SQL, PostgreSQL, NoSQL
                                </div>
                                </div>
                            </li>
                            </Animated>
                        </ul>
                    </div>
            </div>
}
export default AboutMeScreen;