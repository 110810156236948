import React from 'react';
import './ContactItem.css';




class ContactItem extends React.Component {
    render() {
        let menuClick = () => {
            let pageHeight = window.innerHeight;
            window.open(this.props.menuLink, '_blank');
        }
        
        return  (
            <div className={"contact-menu-item-wrapper"} onClick={() => menuClick()}>
                <div className="contact-menu-icon">
                    {this.props.menuIcon}
                </div>
            </div>
            )
        }
  }
  export default ContactItem;