import React from 'react';
import './Footer.css';

class Footer extends React.Component {
    render() {
        return  (
            /*
                Make this open-source & add github details?
            */

            <div className="page-footer-wrapper">

            </div>
            )
        }
  }
  export default Footer;