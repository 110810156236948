import React from 'react';
import './PageMenu.css';

import PageMenuItem from './PageMenuItem';
import twLogo from '../new_logo.png';
import { FaHome } from 'react-icons/fa';
import { MdPerson } from 'react-icons/md';
import { GoTelescope } from 'react-icons/go';
import { AiFillCloud } from 'react-icons/ai';

class PageMenu extends React.Component {    

    constructor()
    {
        super();
        this.state = {
            scrollDirection: null,
        }

    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
      }
    
      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
      }
    
      handleScroll = (event) => {
        // do something like call `this.setState`
        // access window.scrollY etc

        if(this.prevY > window.scrollY)
        {
            this.setState({
                scrollDirection: "up"
            })
        }
        else 
        {
            if(window.scrollY > 100)
            {
                this.setState({
                    scrollDirection: "down"
                })    
            }
        }
        this.prevY = window.scrollY;
    }    

    render() {
        var pages = [
            {
                PageTitle: 'Home',
                menuPosition: 0,
                menuIcon: <FaHome />
            },
            {
                PageTitle: 'About Me',
                menuPosition: 1,
                menuIcon: <MdPerson />
            },
            {
                PageTitle: 'Career',
                menuPosition: 3,
                menuIcon: <AiFillCloud />
            },
            {
                PageTitle: 'Projects',
                menuPosition: 2,
                menuIcon: <GoTelescope />
            },
        ];
        return  (
                <div key={this.scrollDirection} className={"page-menu-wrapper " + this.state.scrollDirection} >
                    <div className="page-menu-container">
                        <div className="logo-container">
                            <img src={twLogo} className="logo-image" />
                        </div>
                        <div className="page-menu-container">
                        {
                        pages.map(
                                (page, i) => <PageMenuItem   key={i}
                                                        menuTitle={page.PageTitle} 
                                                        menuPosition={page.menuPosition}                                                     
                                                        menuIcon={page.menuIcon}
                                                        />
                            )
                        }
                        </div>
                    </div>
                </div>
            );

        }
  }
  export default PageMenu;