import React from 'react';
import './WorkCard.css';
import {Panel, PanelGroup, Timeline} from 'rsuite';
import {Animated} from "react-animated-css";

export const WorkCard = (props) => {
    const { company, description, image, title, startDate, endDate, isVisible} = props;

    return (
        <Animated animationIn="bounceInRight" animationOut="fadeOut" isVisible={isVisible} animationInDelay={500}>
        <Timeline.Item
            className="timeline-item"
            dot={<div className="icon-image-wrapper"><img src={image} className="icon-image" /> </div>} 
            >
                <Panel header={`${company} | ${startDate} - ${endDate}`} shaded accordion>
                <PanelGroup shaded accordion>
                    <Panel header={title}>
                        <div className="work-details-container">
                            <div className="work-details-description">
                                <ul>
                                    {description.map((descriptionText, i) => (
                                        <li key={'description-text-'+i}>
                                            {descriptionText}
                                        </li>
                                    ))}
                                </ul>
                            </div>    
                        </div>                        
                    </Panel>
                    </PanelGroup>
                </Panel>
        </Timeline.Item>
    </Animated>
    );
}

export default WorkCard;