import React from 'react';
import './ProjectScreen.css';
import { GoTelescope } from 'react-icons/go';
import Project from '../../models/Project';
import ProjectTile from '../../components/ProjectTile';
import { Panel, PanelGroup } from 'rsuite';
import purpleGroceryLogo from '../../purplegrocery.png';
import tweeterImage from '../../tweeter.jpeg';

const ProjectScreen = props => {
    const otherProjects = [];
    const featuredProjects = [
        new Project(
            'PurpleGrocery (Web / iOS / Android)',
            purpleGroceryLogo,
            'PurpleGrocery makes meal planning and finding / sharing recipes easy! With nutrition data gathered for each recipe from the US FDC database & generating weekly meal plans for users based on their preferences PurpleGrocery makes eating healthy simple!  This app is available at PurpleGrocery.com and in the Apple App Store / Google Play Store.',
            ['React Native', 'React', 'Node', 'Docker', 'AWS', 'Travis CI', 'Jest'],
            'github.com/TomWrd',
        )];

        return  (
            <div className="projects-container" id="2">
                <h3>
                    Projects
                </h3>
                <div className="featured-projects-container">
                {
                    featuredProjects.map(
                        (project, i) => {
                            return(
                            <ProjectTile 
                                projectTitle={project.projectTitle}
                                projectImage={project.projectImage}
                                projectDescription={project.projectDescription}
                                projectTags={project.projectTags}
                                projectGit={project.projectGit}     
                                imageSide={i%2===1}                       
                            />
                            )
                        }
                    )
                }
                </div>
            </div>
            )
}


export default ProjectScreen;