import React from 'react';
import './WorkScreen.css';
import { Timeline } from 'rsuite';
import threecloudlogo from '../../images/3cloudlogo.png';
import buzzfeedLogo from '../../images/buzzfeedlogo.png';
import AuroraLogo from '../../images/aurora-logo.png';
import uShipLogo from "../../images/uship.png"
import oscarHealth from "../../images/oscar_health.png"

import WorkCard from './WorkCard/WorkCard';


const jobs = [
    {
        title: 'Senior Software Engineer',
        description: [
            'Getting started...',
        ],
        company: 'Oscar Health',
        startDate: 'November 2023',
        endDate: 'Present',
        image: oscarHealth
    },
    {
        title: 'Senior Software Engineer - Frontend',
        description: [
            'Frontend development of AI image and text quizzes. Created a custom rich text prompt editor.',
            <a target="_blank" href="https://www.buzzfeed.com/tomward1/barbie-pet-portait-image-quiz">AI Barbie Pet Quiz</a>
        ],
        company: 'Buzzfeed',
        startDate: 'November 2022',
        endDate: 'August 2023',
        image: buzzfeedLogo
    },
    {
        title: 'Senior Software Engineer - Mobile',
        description: [
            'Develop solutions for mobile using React Native, TypeScript & Node on AWS',
        ],
        company: 'uShip',
        startDate: 'March 2022',
        endDate: 'November 2022',
        image: uShipLogo
    },
    {
        title: 'Software Engineer - Frontend',
        description: [
            'Develop frontend solutions using React, TypeScript & GraphQL with full automated testing coverage using Cypress & Jest.',
            'Review code from other engineers, suggest best practices & interview incoming engineers ',
            'Work in an agile development environment with Jira '
        ],
        company: 'Aurora Solar',
        startDate: 'May 2021',
        endDate: 'March 2022',
        image: AuroraLogo
    },
    {
        title: 'Software Engineer - App Development Consultant',
        description: [
            'Architected chatbots using Azure Bot Framework and created integrations for GIS and reporting with conversational bots.',
            'Created a mobile application for iOS & Android to support our LMS',
            'Architected and developd a chatbot for Alexa, Google Assistant, Web & Facebook using Azure\'s Bot Framework (C#)',
            'Developed extensions for our WordPress LMS to support gamification, reporting & improved UI',
            'Maintained & developed new integrations for company\'s internal C#/.Net CMS'
        ],
        company: '3Cloud',
        startDate: 'November 2018',
        endDate: 'November 2020',
        image: threecloudlogo
    },
]

const WorkScreen = props => {

    const domRef = React.useRef();
    const [isVisible, setVisible] = React.useState(true);

    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      return () => observer.unobserve(domRef.current);
    }, []);
    

    return  (

        <div className="work-container" id="3" ref={domRef}>
            <h3 className="work-container-header">
                Work
            </h3>
            <Timeline>
                {
                    jobs.map((job, i) => (
                        <WorkCard 
                            key={'work-card-'+i}
                            isVisible
                            {...job}
                        />
                    ))
                }
            </Timeline>
        </div>
        )
}
export default WorkScreen;
