import React, { useEffect, useState } from 'react';
import './Cloud.css';
import {Panel, PanelGroup, Tag, TagGroup} from 'rsuite';

const Cloud = props => {
    const screenWidth = window.innerWidth * .5;
    const screenHeight = window.innerHeight;
    var numBumps = props.numBumps;
    var bumps = [];

    var height = Math.floor(Math.random() * 200) + 100;
    var width = Math.floor(Math.random() * (screenWidth - 200 + 1) + 200)
    for(var i = 0; i < Number.parseInt(numBumps); i++) {
        bumps.push({i: i})
    }

    return(
        <div className={"cloud-container " + props.id + " " + props.direction + Math.round(Math.random())} style={{width: '100%',overflow:'visible',height: height, top: (screenHeight * .6) * Math.random(), zIndex: (Math.floor(Math.random() * 10) + 1)}}>
            <div className="cloud-wrapper" style={{width: '100%', overflowY: 'hidden', height: height}}>
            {
                bumps.map(i => (
                    <div className={"cloud-bump " + i.toString()} style={{backgroundColor: props.color, width: (width / (numBumps - 2)) * .8 + "px", marginRight: -1 * (width / (numBumps - 2)) / 2 + "px",  height: height, marginTop: height * ((Math.random() * .5) + .5) }}>

                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default Cloud;