import React from 'react';
import './PageMenuItem.css';

class PageMenuItem extends React.Component {


    render() {
        let menuClick = () => {
            console.log("ID: " + this.props.menuPosition.toString())
            document.getElementById(this.props.menuPosition.toString()).scrollIntoView({behavior: 'smooth'});
        }

        return  (
            <div className={"page-menu-item-wrapper "} onClick={() => menuClick()}>
                <div className="page-menu-icon">
                    {this.props.menuIcon}
                </div>
                <div className="page-menu-title slide-down">
                    <p>{this.props.menuTitle}</p>
                </div>
            </div>
            )
        }
  }
  export default PageMenuItem;